import ScrollReveal from 'scrollreveal';

// options
var slideUp = { distance: '150%', origin: 'bottom', opacity: null, reset: true };
var slideDown = { distance: '150%', origin: 'top', opacity: null, reset: true };
var slideLeft = { distance: '150%', origin: 'right', opacity: null, reset: true };
var slideRight = { distance: '150%', origin: 'left', opacity: null, reset: true };

// call
ScrollReveal().reveal('.slide-up', slideUp);
ScrollReveal().reveal('.slide-down', slideDown);
ScrollReveal().reveal('.slide-left', slideLeft);
ScrollReveal().reveal('.slide-right', slideRight);

// Logo
// var logoSlideIn = { distance: '1000%', origin: 'top', opacity: null, duration: 700, reset: false };
// ScrollReveal().reveal('.logo-slide-in', logoSlideIn);

// Primary Menu
// var menuSlideIn = { distance: '1000%', origin: 'top', opacity: null, duration: 700, reset: false };
// ScrollReveal().reveal('.menu-slide-in', menuSlideIn);

// Hero 
var heroImageSlideIn = { opacity: 0.8, duration: 4000, reset: false };
var heroTitleSlideIn = { distance: '1000%', origin: 'top', opacity: null, duration: 1400, reset: false };
var heroQuoteSlideIn = { distance: '1000%', origin: 'bottom', opacity: null, duration: 1400, delay: 700, reset: false };
ScrollReveal().reveal('.hero-image-slide-in', heroImageSlideIn);
ScrollReveal().reveal('.hero-title-slide-in', heroTitleSlideIn);
ScrollReveal().reveal('.hero-quote-slide-in', heroQuoteSlideIn);